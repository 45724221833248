body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.content-link {
    text-decoration: none;
    color: #1976d2;
}

.content-link:hover {
    text-decoration: underline;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#improvements-area .improvement-area {
    width: 48%;
    display: inline-block;
    margin-bottom: 5px;
}

#improvements-area .improvement-area:nth-child(odd) {
    margin-right: 4%;
}

#improvements-area .improvement-area button {
    width: 100%;
}

#improvements-area .improvement-area button {
    border: 3px solid #eaeaea;
    border-radius: 10px;
}

#improvements-area .improvement-area button.Mui-selected {
    border: 3px solid #48A0F4;
    background-color: white;
}

#improvements-area .improvement-area button.MuiToggleButton-root {
    color: #4a4a4a;
}

tr.filter-row td {
    padding: 0;
}

tr.filter-row td label.MuiInputLabel-root {
    display: none;
}

tr.filter-row td .MuiInput-underline {
    margin: 10px 0;
}

.select-options .MuiButton-root {
    padding: 0;
    min-width: auto;
    margin-top: -2px;
}

.select-options .MuiButton-root:hover {
    text-decoration: underline;
}

.select-options .MuiButton-root .MuiButton-label {
    text-transform: none;
}

.select-options .MuiButton-root .MuiTouchRipple-root {
}

.font-color {
    padding: 5px;
    border: 1px solid #cdcdcd;
    border-radius: 7px;
    width: 100px;
    display: inline-block;
}

.sort-link {
    cursor: pointer;
}

table.MuiTable-root th {
    font-weight: bold;
}

.import-error {
    color: red;
}

.import-ok {
    color: forestgreen;
}

.import-loading {
    color: royalblue;
}

#drawer a.MuiListItem-root.active {
    background-color: #efefef;
}

ul.hovered li {
    border-bottom: 1px solid #f1f1f1;
}

ul.hovered li:hover {
    background-color: #f7eeff;
}
