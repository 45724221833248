@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Amatic+SC:wght@700&family=Anton&family=Baloo+2:wght@500&family=Bebas+Neue&family=Cabin:wght@500&family=Cardo&family=Cinzel:wght@400;700;900&family=Comfortaa:wght@300;400;700&family=Dancing+Script&family=Delius&family=Delius+Swash+Caps&family=Fredericka+the+Great&family=Fredoka+One&family=Gayathri&family=Great+Vibes&family=IBM+Plex+Serif:ital,wght@0,300;1,600&family=Inter:wght@400;500;700&family=Itim&family=Josefin+Sans&family=Josefin+Slab:wght@500;700&family=Jost&family=Kaushan+Script&family=Laila:wght@500&family=McLaren&family=Merriweather&family=Nixie+One&family=Pacifico&family=Parisienne&family=Poppins:ital,wght@1,300&family=Raleway:wght@600&family=Roboto+Mono&family=Satisfy&family=Sofia&family=Special+Elite&family=Syne:wght@500&family=Varela+Round&family=Vidaloka&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.content-link {
    text-decoration: none;
    color: #1976d2;
}

.content-link:hover {
    text-decoration: underline;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#improvements-area .improvement-area {
    width: 48%;
    display: inline-block;
    margin-bottom: 5px;
}

#improvements-area .improvement-area:nth-child(odd) {
    margin-right: 4%;
}

#improvements-area .improvement-area button {
    width: 100%;
}

#improvements-area .improvement-area button {
    border: 3px solid #eaeaea;
    border-radius: 10px;
}

#improvements-area .improvement-area button.Mui-selected {
    border: 3px solid #48A0F4;
    background-color: white;
}

#improvements-area .improvement-area button.MuiToggleButton-root {
    color: #4a4a4a;
}

tr.filter-row td {
    padding: 0;
}

tr.filter-row td label.MuiInputLabel-root {
    display: none;
}

tr.filter-row td .MuiInput-underline {
    margin: 10px 0;
}

.select-options .MuiButton-root {
    padding: 0;
    min-width: auto;
    margin-top: -2px;
}

.select-options .MuiButton-root:hover {
    text-decoration: underline;
}

.select-options .MuiButton-root .MuiButton-label {
    text-transform: none;
}

.select-options .MuiButton-root .MuiTouchRipple-root {
}

.font-color {
    padding: 5px;
    border: 1px solid #cdcdcd;
    border-radius: 7px;
    width: 100px;
    display: inline-block;
}

.sort-link {
    cursor: pointer;
}

table.MuiTable-root th {
    font-weight: bold;
}

.import-error {
    color: red;
}

.import-ok {
    color: forestgreen;
}

.import-loading {
    color: royalblue;
}

#drawer a.MuiListItem-root.active {
    background-color: #efefef;
}

ul.hovered li {
    border-bottom: 1px solid #f1f1f1;
}

ul.hovered li:hover {
    background-color: #f7eeff;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.hovered-image {
    box-shadow: 0 0 15px #cdcdcd;
}

.ColorPicker-MuiSlider-root.ColorPicker-makeStyles-root-48.ColorPicker-MuiSlider-colorPrimary {
    display: none;
}

#platform-box {
    background: rgba(175, 175, 175, 0.2);
    padding: 0 10px;
    border-radius: 25px;
}

