@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Amatic+SC:wght@700&family=Anton&family=Baloo+2:wght@500&family=Bebas+Neue&family=Cabin:wght@500&family=Cardo&family=Cinzel:wght@400;700;900&family=Comfortaa:wght@300;400;700&family=Dancing+Script&family=Delius&family=Delius+Swash+Caps&family=Fredericka+the+Great&family=Fredoka+One&family=Gayathri&family=Great+Vibes&family=IBM+Plex+Serif:ital,wght@0,300;1,600&family=Inter:wght@400;500;700&family=Itim&family=Josefin+Sans&family=Josefin+Slab:wght@500;700&family=Jost&family=Kaushan+Script&family=Laila:wght@500&family=McLaren&family=Merriweather&family=Nixie+One&family=Pacifico&family=Parisienne&family=Poppins:ital,wght@1,300&family=Raleway:wght@600&family=Roboto+Mono&family=Satisfy&family=Sofia&family=Special+Elite&family=Syne:wght@500&family=Varela+Round&family=Vidaloka&display=swap');

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.hovered-image {
    box-shadow: 0 0 15px #cdcdcd;
}

.ColorPicker-MuiSlider-root.ColorPicker-makeStyles-root-48.ColorPicker-MuiSlider-colorPrimary {
    display: none;
}

#platform-box {
    background: rgba(175, 175, 175, 0.2);
    padding: 0 10px;
    border-radius: 25px;
}
